<template>
  <div>
    <v-card>
      <v-toolbar
        color="#689F38"
        class="mb-1"
      >
        <v-row>
          <v-text-field
            v-model="search"
            clearable
            flat
            solo
            hide-details
            label="Search Camp Sessions"
          ></v-text-field>
        </v-row>
      </v-toolbar>
      <v-tabs>
        <v-tab><a @click="filterUpcommingSessions">Current/Upcomming Sessions</a></v-tab>
        <v-tab><a @click="filterPastSessions"> Past Sessions</a></v-tab>
        <v-tab><a @click="viewAllSessions">All Sessions</a></v-tab>
        <!-- <v-btn
            @click="toggleCardView"
            label="Card View"
            color="primary"
            value="list"
            hide-details
        >Card View</v-btn> -->
      </v-tabs>
      <v-data-table
        :headers="headers"
        :items="filterdCampSessions"
        :items-per-page="20"
        class="elevation-1"
      >
        
        <template v-slot:item.startDate="{item}">
          {{isDate(item.startDate)}}
        </template>
        <template v-slot:item.endDate="{item}">
            {{isDate(item.endDate)}}
        </template>
        <template v-slot:item.campSessionId="{item}">
          <v-icon color="primary" depressed @click="previewSession(item)">mdi-eye</v-icon>
          <v-icon color="primary" depressed @click="$router.push(`/camp/session/edit/${item.campSessionId}`)">mdi-pencil</v-icon>
          <v-icon color="primary" depressed @click="ArchiveSession(item.campSessionId)">mdi-delete</v-icon>
          <v-icon :color="item.sessionFull ? 'primary':''" depressed @click="ToggleSessionFull(item)">mdi-alpha-f-box</v-icon>
          <v-icon :color="item.draft ? '' : 'primary'" depressed @click="ToggleSessionPublished(item)">mdi-share</v-icon>

        </template>
      </v-data-table>
    <v-dialog
    v-if="campInfoDialog"
    v-model="campInfo"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
      <SessioninfoDialog :campInfo="campInfo" @campInfoDialog="campInfoDialog = false"></SessioninfoDialog>
    </v-dialog>
    </v-card>

  </div>
</template>

<script>
import moment from 'moment'
import SessioninfoDialog from '@/components/SessionInfoDialog.vue'
import CampService from '@/api-services/camp.service.js';

export default {
  name: 'CampSessionList',
  props: ['campSessions'],
  components: {
    'SessioninfoDialog': SessioninfoDialog
  },
  data () {
    return {
      headers: [
        { text:'Start Date', value: 'startDate'},
        { text:'End Date', value: 'endDate'},
        { text:'Title', value: 'title'},
        { text:'Buttons', value: 'campSessionId'}
      ],
      filterdCampSessions: [],
      filter: {},
      search: '',
      campInfo: {},
      campInfoDialog: false,
      page: 1,
      form: false,
      sortBy: 'startDate',
      objKeys: [
        {dbKey: 'campName', label: "Camp Name"},
        {dbKey: 'startDate', label: "Start Date"},
        {dbKey: 'endDate', label: "End Date"},
        {dbKey: 'title', label: "Title"},
        {dbKey: 'sessionDescription', label: "Description"},
      ],
    }
  },
  watch: {
    campSessions() {
      this.filterUpcommingSessions()
    },
    search (val) {
        if(val === "") {
          this.filterUpcommingSessions()
        } else {
          let filteredSessions = []
          filteredSessions = this.campSessions.filter(item => {
            let obj = Object.values(item)
            obj.forEach(j => {
              if(typeof j === 'string'){
                let inObj = j.toLowerCase().includes(val.toLowerCase())
                if(inObj){
                  let index = filteredSessions.findIndex(el => {return el.campSessionId === item.campSessionId})
                  if(index === -1){
                    filteredSessions.push(item)
                  }
                }
              }
            })
            this.filterdCampSessions = filteredSessions
          })
        }
      }
  },
  computed: {
      numberOfPages () {
        return Math.ceil(this.campSessions.length / this.itemsPerPage)
      },
      filteredKeys () {
        return this.objKeys.filter(key => key.dbKey !== `startDate` && key.dbKey !== `endDate`)
      }
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage (number) {
        this.itemsPerPage = number
      },
      isDate(date) {
        return moment(date).format('l')
      },
      filterPastSessions() {
        const pastSessions = this.campSessions.filter(session => {
          return new Date(session.endDate) < new Date()
        })
        pastSessions.sort(this.dateAsc)
        this.filterdCampSessions = pastSessions
      },
      dateAsc(a, b) {
        return new Date(b.startDate) - new Date(a.startDate)
      },
      filterUpcommingSessions() {
        const upcommingSessions = this.campSessions.filter(session => {
          return new Date(session.endDate) > new Date()
        })
        upcommingSessions.sort(this.dateAsc)
        this.filterdCampSessions = upcommingSessions
      },
      viewAllSessions() {
        const sessions = [...this.campSessions]
        sessions.sort(this.dateAsc)
        this.filterdCampSessions = sessions
      },
      async ArchiveSession(sessionId) {
        await  CampService.archiveCampSession(sessionId) 
        let index = this.campSessions.findIndex(el => {return el.campSessionId === sessionId} )
        this.campSessions.splice(index, 1)
      },
      async ToggleSessionFull(session) {
        const sessionFull = session.sessionFull? 0 : 1
        await  CampService.CampSessionFull(session.campSessionId, sessionFull) 
        session.sessionFull = !session.sessionFull
      },
      async ToggleSessionPublished(session) {
        const newDraftMode = session.draft? 0 : 1
        await  CampService.CampSessionDraft(session.campSessionId, newDraftMode) 
        session.draft = !session.draft
      },
      previewSession(session) {
        this.campInfo = {...session}
        this.campInfoDialog = true
      },
      // toggleCardView() {
      //   this.$emit('updateCardView')
      // }
    }
}
</script>

<style scoped>
#input-usage .v-input__prepend-outer,
  #input-usage .v-input__append-outer,
  #input-usage .v-input__slot,
  #input-usage .v-messages {
    border: 1px dashed rgba(0,0,0, .4);
  }

  .cardValue {
    height: 60px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: flex-start;
    text-align: left;
    display: block;
  }

  .v-list-item {
    height: 40px;
  }

  .v-btn--fab.v-size--default {
    height: 41px;
    width: 43px;
  }

    /* This is the camp session dialog box  */
   .campSessionInfo--img {
    max-height: 90%;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  .campSessionInfo--card {
    height: 300px;
  }

</style>
