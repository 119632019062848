<template>
      <v-card v-if="showAd">
          <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="toggleShowAd()"
            :disabled="closeAd"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="centerAd mt-4">
          <img :src="ad.url" />
        </div>


      </v-card>
      <v-card v-else>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="$emit('campInfoDialog', false)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{campInfo.campName}} <span v-if="campInfo.title"> - </span>{{campInfo.title}}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex justify-start ml-5 mr-5 mt-5 mb-5"> 
          <v-row>
            <v-col :cols=8>
              <v-card class="campSessionInfo--card">
                <v-card-title>Basic Info</v-card-title>
                <v-card-text >
                  <v-row>
                    <v-col :cols=6>
                      <div class="text-left">
                        <span class="font-weight-bold">Location:</span>
                        {{campInfo.addressLine1}}
                      </div>
                      <div class="text-left">
                        <span class="font-weight-bold">Dates:</span>
                        {{startDate}} - {{endDate}}
                      </div>
                      <div class="text-left">
                        <span class="font-weight-bold">Times:</span>
                        {{campStartTime}} - {{campEndTime}}
                      </div>
                    </v-col>
                    <v-col :cols=6>
                      <div class="text-left">
                        <span class="font-weight-bold">Price:</span>
                        ${{campInfo.price}}
                      </div>
                      <div class="text-left">
                        <span class="font-weight-bold">Ages:</span>
                        {{campInfo.ageYoungest}} - {{campInfo.ageOldest}}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                  <v-col :cols=6 class="text-left">
                    <v-chip
                      label
                      text-color="white"
                      :color="beforeCareColor"
                      class="mb-2"
                    >
                      {{beforeCareText}}
                    </v-chip>
                    <v-spacer></v-spacer>
                     <v-chip
                      label
                      text-color="white"
                      :color="afterCareColor"
                    >
                      {{afterCareText}}
                    </v-chip>
                    
                  </v-col>
                  <v-col :cols=6 class="text-left">
                     <v-chip
                      class="mb-2"
                      label
                      text-color="white"
                      color="primary"
                    >
                      {{overnightText}}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-chip
                     class="mb-2"
                      label
                      text-color="white"
                      :color="busColor"
                    >
                      {{busText}}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-chip
                      label
                      text-color="white"
                      :color="mealColor"
                    >
                      {{mealText}}
                    </v-chip>
                   </v-col>
                  </v-row>
                  <h3 class="flex">Categories:</h3>
                  <div class="flex">
                    <v-chip v-for="cat in campInfo.categories" :key="cat"
                      outlined
                      class="ma-2"
                      color="primary"
                    >
                      {{cat}}
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col :cols=4>
              <div class="campSessionInfo--card">
               <v-img class="campSessionInfo--img" 
                :src="campImage"
              >
                </v-img>
               <v-btn @click="BookIt(campInfo)" class="mr-5 mt-3">BookIt</v-btn>
               <v-btn @click="SaveIt(campInfo)" disabled class="mt-3">SaveIt</v-btn>
              </div>
            </v-col>
            <v-col :cols=12>
              <v-card>
                <v-card-title>Session SessionDescription</v-card-title>
                  <v-card-text class="d-flex">
                        {{campInfo.sessionDescription}}
                  </v-card-text>
                </v-card>
              </v-col>
            <v-col :cols=12>
              <v-card>
                <v-card-title>Camp Info</v-card-title>
                  <v-card-text class="d-flex">
                       {{camp.description}}
                  </v-card-text>
                </v-card>
              </v-col>
            <v-col :cols=12>
              <v-card>
                <v-card-title>Other Sessions</v-card-title>
                  <v-card-text class="d-flex">
                    <div class="flex">
                    <v-card v-for="session in campSessions" :key="session.campSessionId"
                      class="ma-2"
                      color="primary"
                    >
                       <v-card-text>
                         <h4>{{session.title}}</h4>
                        <p>{{formatdate(session.startDate)}} - {{formatdate(session.endDate)}}</p>
                       </v-card-text>
                    </v-card>
                  </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
</template>

<script>
import moment from 'moment'
import CampFinder from '@/api-services/finder.service.js'
import azure from '@/api-services/azure.service.js'

export default {
  name: 'SessioninfoDialog',
  data () {
    return {
      camp: this.$store.state.camp,
      startDate: moment(this.campInfo.startDate).format('M-D-YYYY'),
      endDate: moment(this.campInfo.endDate).format('M-D-YYYY'),
      campStartTime: moment(this.campInfo.campStartTime, "HH:mm").format("h:mm a"),
      campEndTime: moment(this.campInfo.campEndTime, "HH:mm").format("h:mm a"),
      beforeCareColor: this.campInfo.beforeCare ? '#689F38' : '#757575',
      beforeCareText:  this.campInfo.beforeCare ? `Before Care Starts at ${moment(this.campInfo.beforeCareStart, "HH:mm").format("h:mm a")}` : 'No Before Care',
      afterCareColor: this.campInfo.afterCare ? '#689F38' : '#757575',
      afterCareText:  this.campInfo.afterCare ? `After Care Ends at ${moment(this.campInfo.afterCareEnd, "HH:mm").format("h:mm a")}` : 'No After Care',
      overnightText: this.campInfo.overnight ? 'Overnight Camp' : 'Day Camp',
      busText: this.campInfo.bus ? 'Bus Service' : 'No Bus Service',
      busColor: this.campInfo.bus ? '#689F38' : '#757575',
      mealText: this.campInfo.meal ? 'Meals Served' : 'No Meals Served',
      mealColor: this.campInfo.Meal ? '#689F38' : '#757575',
      campSessions: [],
      campImage: "",
      showAd: true,
      closeAd: false,
      ad: {
        title: "popUpAdBackup",
        url: "/popUpAdBackup.jpg"
      }
    }
  },
  props: ['campInfo'],
  async mounted() {
    // setTimeout(() => this.closeAd = false, 10000);
    await CampFinder.getCamp(this.campInfo.campId)
    .then(async (response) =>  {
      this.camp = response.data
      this.$store.commit('updateCamp', response.data)
    })
    await CampFinder.getAllCampSessions(this.campInfo.campId)
    .then(async (response) =>  {
      this.campSessions = response.data
      this.filterUpcommingSessions()
    })
    await this.GetImage()

    const newAd = await azure.GetAds(3, 1);
    this.ad = { 
      title: newAd?.data[0]?.fileName,
      url: newAd?.data[0]?.url
    }
    
    

  },
  computed: {
  },
  methods: {
    async GetImage() {
      let url = await azure.GetImageUrl(this.$store.state.camp.bucket, this.$store.state.camp.bucketKey)
      this.campImage = (url && url.data) ? url.data : "../img/PlaceYourAdHereCampIt.jpg"
    },
    formatdate(d) {
      return d.split(" ")[0]
    },
    BookIt(camp){
      const hasHttps = camp.bookItUrl.split(':')[0] 
      const url = hasHttps === 'https' ? camp.bookItUrl : `https://${camp.bookItUrl}`
      window.open(url, "_blank")
    },
    closeCampInfo() {
      this.campInfo = {}
      this.campInfoDialog = false
    },
    filterUpcommingSessions(){
      this.campSessions = this.campSessions.filter(session => {
        return new Date(session.endDate) > new Date()
      })
    },
    toggleShowAd() {
      this.showAd = !this.showAd
    }
  },
  watch: {
    camps(newVal) {
      this.filteredCamps = newVal
    }
  }
}
</script>

<style scoped>
  
 .v-btn {
    background-color: #689F38 !important;
    border-radius: 3px;
    color: #ffffff;
  }
/* This is the camp session dialog box  */
  .campSessionInfo--img {
    max-height: 90%;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto
    /* width: 500px;
    height: 300px; */
  }

  .campSessionInfo--card {
    height: 350px;
  }
 
  .flex {
    display: flex;
    flex-wrap: wrap;
  }


  .centerAd {
      text-align: center;
      margin: 100 px;
    }

    .centerAd img {
      max-width: 100%;
      max-height: 100%;
    }
</style>
