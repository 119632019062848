<template>
  <div class="login">
    <v-container fluid>
      <Navbar></Navbar>
      <v-row class="mt-3">
        <v-col cols="12" sm="12" md="12" lg="12"
          v-for="ad in bannerAds" :key="ad.title"
        >
          <div class="bannerAd">
            <v-img :src="ad.url"></v-img>
          </div>
        </v-col>
      </v-row>
      <v-row class="managerRows">
        <v-col>
          <v-btn color="primary" @click="$router.push('/camp/sessions/add')">Add Sessions</v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" @click="$router.push('/managecamp')">Manage Camp Profile</v-btn>
        </v-col>
      </v-row>
     
      <CampsList :campSessions="allCampSessions"  />
    </v-container>
  </div>
</template>

<script>
import CampsList from '@/components//CampsList.vue'
import Navbar from '../components/Navbar.vue';
import CampService from '@/api-services/camp.service.js';
import azure from '@/api-services/azure.service.js'

export default {
  name: 'Camp',
  components: {
    "CampsList": CampsList,
    "Navbar": Navbar
  },
  data () {
    return {
      allCampSessions: this.campSessions,
      filterdCampSessions: [],
      campId: this.$store.state.campId,
      campName: this.$store.state.campName,
      campLogo: this.$store.state.campLogo,
      campURL: this.$store.state.campURL,
      userId: this.$store.state.userId,
      bannerAds: []
    }
  },
  async mounted() {
    const campSessions = await CampService.getAllUserCampSessions()
    const camp = await CampService.getUserCamp()
    this.$store.commit('updateCamp', camp.data)
    this.allCampSessions = campSessions.data

    const bannerAdUrls = await azure.GetAds(4, 1);
    this.bannerAds = bannerAdUrls.data.map(ad => {
      return {
        title: ad.fileName,
        url: ad.url
      }
    })

  }
}
</script>
<style scoped>
.managerRows {
  margin: 10px 0;
}
</style>
