/* eslint-disable */
import Axios from 'axios';
import api from './api';


const RESOURCE_NAME = process.env.VUE_APP_BASE_URL;

export default {
  findCamps() {
    return Axios.get(RESOURCE_NAME+'/campfinder', {
      headers: {
        // 'Authorization': `Bearer ${jwt}`
      }
    });
  },
  getAllCampSessions(id) {
    return Axios.get(RESOURCE_NAME+`/campfinder/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  getCamp(id) {
    return Axios.get(RESOURCE_NAME+`/campfinder/camp/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
};