/* eslint-disable */
import Axios from 'axios';
import api from './api';

const RESOURCE_NAME = process.env.VUE_APP_BASE_URL;
const RESOURCE_NAME_API = process.env.VUE_APP_BASE_URL;

export default {
  async GetImageUrl(containerName, imageName) {
    try {
      const url = api.axiosGet(`/AzureStorage/azureimageUrl/${containerName}/${imageName}`);
      return url;
    } catch(exception) {
      console.log("something went wrong", exception);
      return "";
    }
  },
  async GetAds(adType, adNum) {
    try {
      const urls = api.axiosGet(`/AzureStorage/getads/${adType}/${adNum}`);
      return urls;
    } catch(exception) {
      console.log("something went wrong", exception);
      return "";
    }
  },
  // async GetBannerAds() {
  //   try {
  //     const urls = api.axiosGet(`/AzureStorage/getbannerads`);
  //     return urls;
  //   } catch(exception) {
  //     console.log("something went wrong", exception);
  //     return "";
  //   }
  // },
  async GetAdUrls(bucket, key) {
    return Axios.post(`${RESOURCE_NAME}/AzureStorage/getfileslist`,  {
      "bucket": "camp-it-dev",
      "folder": "ads"
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  async GetPresignedPutUrl(containerName, imageName) {
    try {
      return api.axiosGet(`/AzureStorage/azurepostimageUrl/${containerName}/${imageName}`);;
    } catch(exception) {
      console.log("something went wrong", exception);
      return "";
    }
  },
  async PutImageToAzureContainer(url, file) {
    fetch(url, {
      method: 'PUT',
      body: file,
      headers: { 
        'Content-Type': 'multipart/form-data',
        "x-ms-blob-type": "BlockBlob"
      }      
    })
    .catch(error => console.error('Error:', error))
  }
}